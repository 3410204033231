<template>
	<div class="Setting">
		<BackButton ref="back" />
		<TextKR kr="환경" size="small" ref="title">
			Settings
		</TextKR>
	</div>
</template>

<script>
import { gsap, Quad, TimelineMax } from 'gsap/all'
import UserData from '@/data/UserData'

export default {
	name: 'Setting',
	components: {
	},
	computed: {
		isLogin () {
			return UserData.isLogin.value
		}
	},
	methods: {
		transitionIn () {
			const tl = new TimelineMax({ delay: 0.3 })
			tl.staggerFromTo(this.$el.querySelectorAll('p,h2'), 0.5, { opacity: 0, x: -50 }, {
				opacity: 1,
				x: 0,
				ease: Quad.easeOut,
				duration: 0.5
			}, 0.1)

			gsap.from(this.$refs.title, { opacity: 0, y: 30, ease: Quad.easeOut })
		},
		transitionOut (cb) {
			this.$refs.back.hide()
			this.$refs.title.hide()
			gsap.to(this.$refs.title, { opacity: 0, y: -30, ease: Quad.easeOut })
			const tl = new TimelineMax({ delay: 0.1 })
			tl.staggerTo(this.$el.querySelectorAll('p,h2'), 0.5, { opacity: 0, x: 80, ease: Quad.easeOut }, 0.1, 0, cb)
		}
	},
	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.transitionIn()
		})
	},
	beforeRouteLeave (to, from, next) {
		this.transitionOut(next)
	}
}
</script>

<style lang="stylus" scoped>
.Setting
	color #fff
</style>
